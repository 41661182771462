<template>
  <v-card height="100%" class="" elevation="1">
    <ConversationPublic :public="true" :placeholder="placeholder" @updateSideBar="triggerUpdateSidebar" @getChatByToken="getChatByToken" :prop_selected_chat="selected_chat" chat="selected_chat" :icons="icons"/>
  </v-card>
</template>
<script>
import {mdiMagnify, mdiFilterVariant, mdiSend, mdiClose, mdiFile, mdiArrowDown} from '@mdi/js'
import ConversationPublic from "@/views/AgentsControl/ConversationPublic";

export default {
  data: () => ({
    token: null,
    placeholder: 'Loading chat....',
    selected_chat: null,
    conversations_loading: false,
    icons: {
      mdiMagnify,
      mdiFilterVariant,
      mdiSend,
      mdiClose,
      mdiFile,
      mdiArrowDown
    },
  }),
  components: {
    ConversationPublic
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

  },
  methods: {
    getChatByToken() {
      this.errors = null
      this.conversations_loading = true
      this.$http({
        method: 'get',
        url: `/v1/conversations/by-token?token=${this.token}`,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.selected_chat = response.data.conversation
      }).catch(err => {
        this.$store.state.errors = err.response.data
      }).finally(() => {
        this.conversations_loading = false
      })
    },
    triggerUpdateSidebar() {
      //noting to do
    },
  },
  mounted() {
    this.token = this.$route.params.token
    this.getChatByToken()
  },
};
</script>
<style>
.border {
  border-right: 1px solid #e5e5e5;
}

.agent-control-chat {
  height: 300px!important;
  position: absolute!important;
  margin: auto!important;
  left: 0!important;
  right: 0!important;
  top: 0!important;
  bottom: 10%!important;
}
</style>
